import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LineDivider from "../../components/LineDivider";

const PerbankanSyariah = () => {
	return (
		<>
			<Header />
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-8 col-sm-4">
						<h2 className="h2 mt-5">Perbankan Syariah</h2>
						<LineDivider />
						<h5 className="h5 mt-2">Sejarah</h5>
						<p align="justify">
							Keinginan sebagian masyarakat muslim untuk menjalankan syariat Islam dalam segala aspek kehidupan semakin kencang, termasuk dalam hal bermuamalah, yang ditandai dengan semakin pesatnya perkembangan bank-bank syariah yang
							menerapkan prinsip-prinsip syariat Islam, sehingga diperlukan tenaga Perbankan Syariah maupun Lembaga Keuangan Syariah yang profesional. Melihat realitas masyarakat dan peluang yang ada, Fakultas Syariah dan Hukum UNSIQ Jawa
							Tengah di Wonosobo pada tahun 2014 membuka Program Studi S1 Perbankan Syariah.
						</p>
						<p align="justify">
							Pembentukan Program Studi S1 Perbankan Syariah pertama-tama mengacu kepada Keputusan Direktur Jenderal Pendidikan Islam Kementerian Agama RI Nomor: Dj.I/Dt.I.IV/PP.00.9/1565/2014 tentang izin Pembukaan Program Studi S1 Pada
							Perguruan Tinggi Agama Islam Swasta Tahun 2014 yang ditandatangani oleh Direktur Jenderal Pendidikan Islam. 01 Juli 2014. Adapun Izin Operasional diberikan berdasarkan SK Direktur Jenderal Pendidikan Islam Nomor 3656 Tahun
							2014 tanggal 01 Juli 2014. Dan tahun 2018 berdasarkan Surat Keputusan Badan Akreditasi Nasional Perguruan Tinggi Nomor: 1831/SK/BAN-PT/Akred/S/VII/2018 Program Studi Perbankan Syari’ah telah terakreditasi B. serta pada tahun
							2021 Program Studi Perbankan Syari’ah bergabung di Fakultas Ekonomi dan Bisnis UNSIQ Jawa Tengah di Wonosobo berdasarkan Surat Keputusan Rektor Universitas Sains Al-Qur’an Nomor: 179/SK/UNSIQ/IX/2021.
						</p>
						<LineDivider />
						<h5 className="mt-3">1. Visi</h5>
						<p className="ms-3">“Menjadi Program Studi yang menjunjung nilai-nilai Islam, unggul di bidang perbankan syari’ah, dan kewirausahaan pada tahun 2031”.</p>
						<h5 className="mt-3">2. Misi</h5>
						<ul>
							<li>
								Menyelenggarakan pendidikan tinggi di bidang perbankan syari’ah untuk menghasilkan sumber daya manusia yang handal, kreatif, dan inovatif sesuai kebutuhan pasar global yang dilandasi nilai-nilai Islami (transformatif, humanis, dan Qur’ani), serta berjiwa entrepreneurship.
								</li>
							<li>
								Menyelenggarakan penelitian dan pengembangan ilmu pengetahuan dan teknologi khususnya di bidang ekonomi dan bisnis untuk meningkatkan integritas kepribadian lulusan, menggabungkan jiwa kepemimpinan, dan etika profesional bidang perbankan syari’ah dan kewirausahaan yang berakhlakul karimah.
							</li>
							<li>
								Menyelenggarakan pengabdian kepada masyarakat yang memadukan ilmu ekonomi  dengan nilai-nilai Islam.
							</li>
							<li>
								Membangun tata kelola Program Studi yang baik untuk memperkuat sistem tata pamong yang transparan, akuntabel, dan berkualitas.  
							</li>
						</ul>
						<h5 className="mt-3">3. Tujuan</h5>
						<ul>
							<li>
								Menghasilkan sarjana Perbankan Syari’ah yang mempunyai kapasitas.keilmuan dan keahlian.mampu bersaing secara global yang kreatif, inovatif, dan berjiwa entrepreneurship berlandaskan nilai-nilai Al-Qur’an.
							</li>
							<li>
								Meghasilkan.karya penelitian secara multidisipliner yang mampu menjawab persoalan masyarakat sehingga dapat meningkatkan harkat dan martabat manusia.
							</li>
							<li>
								Melaksanakan kegiatan pelayanan.dan pengabdian kepada masyarakat di bidang ekonomi Islam. 
							</li>
						</ul>
						<h5 className="mt-3">4. Sasaran</h5>
						<ul>
							<li>Agar lulusan mampu menganalisis dan memberikan solusi permasalahan ekonomi Islam di masyarakat.</li>
							<li>Agar lulusan mampu mengaplikasikan ilmu ekonomi Islami di bidang kewirausaan sesuai dengan kompetensi.</li>
							<li>Agar dapat memberikan kontribusi kepada masyarakat dibidang ekonomi Islam.</li>
							<li>Agar kualitas kinerja Lembaga meningkat secara berkesinambungan.</li>
							<li>Agar dapat mengembangkan dan meningkatkan kualitas Tri Darma Perguruan Tinggi lembaga.</li>
						</ul>
						<h5 className="mt-3">5. Strategi</h5>
						<ul>
							<li>Melakukan penataan kurikulum yang memadukan ilmu Al Quran dan ilmu ekonomi Islami dengan nilai-nilai kepesantrenan.</li>
							<li>Memfasilitasi kegiatan penelitian dan melakukan pemutakhiran hasil penelitian secara berkala agar lebih bermanfaat bagi masyarakat.</li>
							<li>Memfasilitasi dan menyelengarakan pengabdian yang berbasis ekonomi Islam agar bermanfaat bagi masyarakat luas.</li>
							<li>Menguatkan organisasi yang berbasis teknologi dan meningkatkan sumberdaya manusia yang ber-akhlak (akuntabel, kompeten, harmonis, loyal, adaptif, dan kolaboratif).</li>
							<li>Membentuk tim Kerjasama dengan berbagai pihak baik dalam negeri maupun luar negeri.</li>
						</ul>
						<h5 className="mt-3">6. Profil Lulusan</h5>
						<ul>
							<li>Praktisi Perbankan Syari’ah</li>
							<li>Akademisi</li>
							<li>Pengusaha Islam</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default PerbankanSyariah;
